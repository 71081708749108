@value colors: "../../colors.css";
@value light-grey, medium-grey from colors;

.rows-container {
  display: flex;
  flex-direction: column;
}

.header-column {
  padding: 5px;
}

.device-header {
  width: 58%;
}

.device-row {
  width: 58%;
}

.aside-for-header {
  width: 21%;
}

.aside-for-body {
  width: 21%;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.header-container {
  background-color: light-grey;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-shadow: 2px 2px 2px medium-grey;
  opacity: 0.99;
}

.body-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}