@value colors: "../../colors.css";
@value blue-active from colors;

.edit-schedule-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 2% 2% 2% 2%;
  }
  
  .form-control {
    width: 13rem;
  }
  
  .label-container {
    display: flex;
    flex-direction: row;
  }

  .schedule-builder-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .schedule-details-editor-container {
    margin-left: 5rem;
  }

  .return-to-scheduling-link {
    color: blue-active;
    margin: 11rem 0 0 1rem;
  }