@value colors: "../../colors.css";
@value light-grey, medium-grey from colors;

.rows-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 17rem;
}

.header-column {
  padding: 5px;
  font-weight: 600;
}

.device-header {
  width: 54%;
}

.device-row {
  width: 58%;
}

.aside-for-header {
  width: 23%;
}

.aside-for-body {
  width: 21%;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.header-container {
  background-color: light-grey;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-shadow: 2px 2px 2px medium-grey;
  opacity: 0.99;
}

.body-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.profile-name {
  text-align: left;
}