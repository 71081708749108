@value colors: "../colors.css";
@value red-active from colors;

.device-status-wrapper {
    width: 100%;
}

.status-text {
    margin-left: 3px;
}

.radio-silence-icon-wrapper {
    background-color: red-active;
    border-radius: 5px;
    padding: 2px;
    margin-right: 3px; 
}

.radio-silence-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}