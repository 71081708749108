.wrapper {
    display: flex;
    align-items: center;
}

.text {
}

.start {
    order: 0;
}

.end {
    order: 99;
}