.form-control {
  width: 18rem;
}

.command-wrapper {
  display: flex;
  flex-direction: column;
}

.not-validation-error-text {
  width: 9rem;
}

.schedule-wrapper {
  display: flex;
  flex-direction: column;
}