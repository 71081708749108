@value colors: "../../../../colors.css";
@value light-grey from colors;

.header {
    background-color: light-grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0.8rem 0 0.8rem 0;
}

.gsm-column {
    padding: 0.1rem;
    width: 10%;
}

.battery-column {
    padding: 0.1rem;
    width: 10%;
}

.relay-column {
    padding: 0.1rem;
    width: 10%;
}

.sorting-by-name-column {
    padding: 0.1rem;
    width: 10%;
}