@value colors: "../../colors.css";
@value light-grey, medium-grey from colors;

.schedule-builder-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 2% 2% 2% 2%;
}

.schedule-builder-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.add-schedule-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 5rem;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.schedules-list-container {
  display: flex;
  flex-direction: row;
  margin: 1% 0;  
  text-align: left;
}

.own-schedules-list-container {
  margin: 0 1.5% 0 3%;
  width: 55%;
}

.shared-schedules-list-container {
  margin: 0 3% 0 1.5%;
  width: 45%;
}