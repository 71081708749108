@value colors: "../../../colors.css";
@value white from colors;

.wrapper {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 0.3rem;
    padding: 5px;
}

.text {
    margin-right: 3px;
}