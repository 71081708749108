@value colors: "../../../colors.css";
@value light-grey, light-blue from colors;

.header {
    background-color: light-blue;
    display: flex;
    flex-direction: column;
}

.header-buttons-container {
    background-color: light-grey;
    display: flex;
    flex-direction: row;
    padding: 0.8rem 0 0.8rem 0;
}

.title-header-column {
    display: flex;
    justify-content: center;
    width: 25%;
}

.description-header-column {
    display: flex;
    justify-content: center;
    width: 25%;
}

.scheduleString-header-column {
    display: flex;
    justify-content: center;
    width: 25%;
}

.delete-schedule-header-column {
    display: flex;
    justify-content: center;
    width: 25%;
}

.search-container {
    background-color: #f2f2f2;
    padding: 0 0.5rem;
}

.search-input-control {
    width: 100%;
}

.title-container {
    padding: 0 0.5rem;
}