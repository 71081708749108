@value colors: "../../../colors.css";
@value white from colors;

.wrapper {
    background-color: white;
    border-radius: 25px;
    padding-bottom: 2px;
}

.start {
    order: 0;
}

.end {
    order: 99;
}