@value colors: "../../../colors.css";
@value light-blue, light-orange, medium-grey from colors;

.row {
    background-color: light-blue;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 2px 0;
}

.selected-to-delete {
    background-color: light-orange;
}

.wrapped-area {
    box-shadow: 2px 2px 2px medium-grey;
    opacity: 0.99;
}

.title-column {
    display: flex;
    justify-content: flex-start;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 19%;
    margin-left: 5%;
    margin-right: 2%;
    padding: 0.3rem;
}

.description-column {
    display: flex;
    justify-content: flex-start;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 19%;
    padding: 0.3rem;
}

.scheduleString-column {
    display: flex;
    justify-content: flex-start;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 55%;
    padding: 0.3rem 0.5rem;
}

.copy-schedule-column {
    display: flex;
    justify-content: flex-start;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 7%;
    padding: 0.1rem;
}

.copy-schedule-icons {
    display: flex;
    flex-direction: row;
}