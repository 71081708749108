.form-control {
    height: 2.7rem;
    width: 9rem;
  }
  
  .relay-select {
    height: 2.5rem;
  }
  
  .not-validation-error-text {
    width: 9rem;
  }