.input-container {
    width: 8.1rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.information {
    width: 8.1rem;
}