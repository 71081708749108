.mqtt-connect  {
    display: flex;
    flex-direction: column;
    width: max-content;
  }

  .mqtt-connect  {
    margin: 30px 0 50px 0;
  }

  .mqtt-connect input {
    margin-right: 10px;
  }

  .form-control-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .input-container {
    display: flex;
    align-items: center;
  }

  .tls-checkbox-container {
    display: flex;
    align-items: center;
  }

  .input-adornment {
    width: 2rem;
    padding: 0;
  }

  .form-control {
    width: 14rem;
  }

  .connection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }