.radio-mode-container {
    width: 10rem;
}

.form-control {
    width: 10rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.information {
    width: 7.1rem;
}