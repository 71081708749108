.gsm-module-container {
    width: 12rem;
}

.phone-number-container {
    width: 12rem;
}

.form-control {
    width: 12rem; 
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.information {
    width: 12.1rem;
}