.schedule-builder-container {
    display: flex;
    flex-direction: column;
}

.schedule-commands-container {
    display: flex;
    flex-direction: column;
}

.schedule-string-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
}

.schedule-string-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.schedule-string-control {
    width: 95%;
}

.commands-group {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
