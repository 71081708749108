.form-control-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 14rem;
}

.input-container {
    display: flex;
    align-items: center;
}

.input-adornment {
    width: 2rem;
    padding: 0;
}

.form-control {
    width: 14rem;
}