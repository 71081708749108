@value colors: "../../colors.css";
@value light-blue, light-orange, medium-grey from colors;

.row {
    background-color: light-blue;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 2px 0;
}

.selected-to-delete {
    background-color: light-orange;
}

.wrapped-area {
    box-shadow: 2px 2px 2px medium-grey;
    opacity: 0.99;
}

.clientId-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 30%;
    margin-left: 5%;
    margin-right: 2%;
    padding: 0.3rem;
}

.clientName-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 30%;
    padding: 0.3rem;
}

.profile-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 20%;
    padding: 0.3rem;
}

.select-device-column {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 10%;
    padding: 0.3rem;
}

.delete-device-column {
    display: flex;
    justify-content: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 10%;
    padding: 0.3rem;
}

.ownerUsername-column {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-wrap: anywhere;
    min-width: 1rem;
    width: 10%;
    padding: 0.3rem;
}

.row-wrapper {
}

.select-device-checkbox {
    height: 1.2rem;
    width: 1.2rem;
}