@value colors: "../../../../colors.css";
@value light-grey from colors;

.header {
    background-color: light-grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.8rem 0 0.8rem 0;
}

.radio-silence-column {
    padding: 0.1rem;
    width: 14%;
}

.gsm-audio-mode-column {
    padding: 0.1rem;
    width: 16%;
}

.radio-audio-mode-column {
    padding: 0.1rem;
    width: 15%;
}

.gsm-column {
    padding: 0.1rem;
    width: 15%;
}

.battery-column {
    padding: 0.1rem;
    width: 13%;
}

.schedule-column {
    padding: 0.1rem;
    width: 16%;
}

.sorting-by-name-column {
    padding: 0.1rem;
    width: 11%;
}