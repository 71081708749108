@value colors: "../../colors.css";
@value blue-active, white from colors;

.visibility-icon-wrapper {
    background-color: blue-active;
    border-radius: 5px;
    display: flex;    
    align-items: center;
    justify-content: center;
    height: 1.8rem;
    width: 1.8rem;
}

.visibility-icon {
    color: white;
}