@value colors: "./colors.css";
@value medium-grey, dark-grey from colors;

.navigation {
    background-color: medium-grey;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    align-items: center;
    opacity: 0.9;
    padding: 0.5rem 0 0.5rem 0;
}

.logout-link {
    text-decoration: none;
    color: dark-grey;
}

.account-menu {
   height: 1.5rem;
}

.account-box-icon {
    width: 2rem;
    height: 2rem;
}