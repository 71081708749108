@value colors: "../../../colors.css";
@value white from colors;

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 0.3rem;
    padding: 5px;
}

.start {
    order: 0;
}

.end {
    order: 99;
}