.gsm-phone {
    cursor: pointer;
}

.gsm-status-typography {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    overflow: auto;
    padding: 1rem 4rem 1rem 1rem;
}

.close-button {
    position: absolute;
    top: 0.3rem;
    right: 1rem;
}