@value colors: "../../../../colors.css";
@value light-blue, white, light-orange, light-grey, medium-grey from colors;

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 3px 0;
}

.column {
    display: flex;    
    flex-direction: row;
    justify-content: center;
}

.gsm-column {
    width: 16%;
}

.di-column {
    width: 15%;
}

.select-device-column {
    width: 15%;
}

.commands-row {
    background-color: white;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
}

.name {
    display: flex;
    align-items: flex-start;
    max-width: fit-content;
    padding: 0.4rem;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    margin-left: 1rem;
}

.online {
    background-color: light-blue;
    box-shadow: 2px 2px 2px medium-grey;
}

.offline {
    background-color: light-orange;
    box-shadow: 2px 2px 2px medium-grey;
}

.wrapped-area {
    box-shadow: 2px 2px 2px medium-grey;
    opacity: 0.99;
}

.commands-row-wrapper {
    margin: 1rem 0;
}

.row-wrapper {
}

.select-device-checkbox {
    height: 1.2rem;
    width: 1.2rem;
}

.select-device-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: break-word;
    min-width: 1rem;
    width: 15%;
    padding: 0.3rem;
}

.select-device-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}