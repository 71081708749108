.wrapper {
    display: flex;
    align-items: center;
}

.text {
    margin-left: 3px;
}

.start {
    order: 0;
}

.end {
    order: 99;
}