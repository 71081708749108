.add-device-wrapper {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.form-control {
  width: 13rem;
}

.label-container {
  display: flex;
  flex-direction: row;
}