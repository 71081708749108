.commands-row {
    background-color: white;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-evenly;
}

.commands-row-wrapper {
    margin: 1rem 0;
}

.select-all-devices-checkbox {
    height: 1.2rem;
    width: 1.2rem;
}

.select-all-devices-checkbox-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.control-panel-wrapper {
    display: flex;
    flex-direction: column;
}